import { CallCenterPartnerEnum } from '@/enums/crm/call-center/call-center-partner.enum';

export default class SettingsModel {
  flagHabilitaNovoPainelCliente!: boolean;

  flagConverteProspectIGS!: boolean;

  flagIntegraClienteAposConversaoProspect!: boolean;

  flagUsaSegundaViaBoletos!: boolean;

  flagIntegracaoSintegraAtivo!: boolean;

  urlCrmApp!: string;

  flagObrigaClassificacaoFechamentoConversa!: boolean;

  flagObrigaDescricaoFechamentoConversa!: boolean;

  flagTornarCnpjProspectObrigatorio!: boolean;

  flagUtilizaWhatsapp!: boolean;

  flagUtilizaAgenda!: boolean;

  flagExcluiAgenda!: boolean;

  loggedUserType!: number;

  flagPermiteExcluirAnexos!: boolean;

  flagMostraChatsUsuariosRootVisaoGeral!: boolean;

  flagObrigaClassificacaoEmail!: boolean;

  flagHabilitaMenuVisitas!: boolean;

  flagPermiteEditarParticularidades!: boolean;

  flagHabilitaMenuClientesCidadeProspect!: boolean;

  flagCongelaPreenchimentoSintegra!: boolean;

  flagNaoPreencheTelefoneEmailProspectSintegra!: boolean;

  flagEmailProspectObrigatorio!: boolean;

  flagTelefoneProspectObrigatorio!: boolean;

  modoOperacaoUra?: string;

  flagPermiteCadastroAtendenteChave!: boolean;

  flagMenuVendasColecaoErp!: boolean;

  flagRemoveObrigatorioRepresentanteAgenda360!: boolean;

  flagApresentaRepresentantesInativosAgenda360!: boolean;

  flagIntegracaoClienteUnicoAtivo!: boolean;

  flagPermiteClientesSituacao99Crm360!: boolean;

  realizaEnvioCampanhasInformativos!: boolean;

  flagEnviaContatoErp!: boolean;

  tituloColunaCoringa1Pedidos!: string;

  flagGeoCodificacao!: boolean;

  urlAcessoExterno!: string;

  urlAcessoInterno!: string;

  urlAcessoGeo360!: string;

  flagWhatsApp360Ativo!: boolean;

  flagUtilizaTelefonia!: boolean;

  partnerCodeCallCenter!: number;

  get isCloudTalkPartner(): boolean {
    return this.partnerCodeCallCenter === CallCenterPartnerEnum.CloudTalk.valueOf();
  }
}
